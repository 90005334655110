@font-face{
	font-family: "Rubik";
	src: url('../fonts/Rubik-Regular.ttf') format('ttf');
	font-weight: 400;
	font-style: "normal";
  }
@font-face{
	font-family: "Rubik";
	src: url('../fonts/Rubik-Medium.ttf') format('ttf');
	font-weight: 500;
	font-style: "normal";
  }
@font-face{
	font-family: "Rubik";
	src: url('../fonts/Rubik-Bold.ttf') format('ttf');
	font-weight: 700;
	font-style: "normal";
  }
@font-face{
	font-family: "Rubik";
	src: url('../fonts/Rubik-ExtraBold.ttf') format('ttf');
	font-weight: 800;
	font-style: "normal";
  }
@font-face{
	font-family: "Inter";
		src: url('../fonts/Inter-Regular.ttf') format('ttf');
	font-weight: 400;
	font-style: "normal";
  }
@font-face{
	font-family: "Inter";
		src: url('../fonts/Inter-Medium.ttf') format('ttf');
	font-weight: 500;
	font-style: "normal";
  }
@font-face{
	font-family: "Inter";
		src: url('../fonts/Inter-Bold.ttf') format('ttf');
	font-weight: 700;
	font-style: "normal";
  }
@font-face{
	font-family: "Inter";
		src: url('../fonts/Inter-SemiBold.ttf') format('ttf');
	font-weight: 800;
	font-style: "normal";
  }
@font-face{
	font-family: "Inter";
		src: url('../fonts/Inter-Light.ttf') format('ttf');
	font-weight: 400;
	font-style: "normal";
  }
@font-face{
	font-family: "Helvetica";
		src: url('../fonts/Helvetica.ttf') format('ttf');
	font-weight: 400;
	font-style: "normal";
  }
@font-face{
	font-family: "Helvetica";
		src: url('../fonts/Helvetica-Bold.ttf') format('ttf');
	font-weight: 700;
	font-style: "normal";
  }
