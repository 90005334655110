@import './_base.scss';
@import '_variable.scss';
@import './_fonts.scss';

body {
  font-family: 'Rubik', Arial, sans-serif;
  margin: 0;
}

#page-wrapper {
  position: inherit;
  overflow: hidden;
  min-height: calc(100vh - 20px);
  background-image: url(../images/Homepage_BG.png);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.page-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  padding: 30px;
  height: calc(100vh - 60px);
  max-width: 1920px;
  margin: 0 auto;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    color: #2a2a2a;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  a {
    color: #2a2a2a;
    text-decoration: none;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid transparent;
    transition: 0.2s;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
      border-bottom: 2px solid white;
      color: #00a3ff;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.html-content-wrapper {
  padding: 20px;
}

// ----------------------------------------- Loader CSS -----------------------------------------------

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgb(46, 54, 70, 0.2) 33%, $navyBlue 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: $green;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.homepage-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .info {
    h1 {
      color: #2b3446;
      font-family: 'Rubik', Arial, sans-serif;
      font-size: 70px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -2px;
      margin-bottom: 20px;
    }

    h3 {
      color: #2e3646;
      font-family: 'Inter', Arial, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 630px;
      margin: 0;
      margin-bottom: 40px;
    }

    h4 {
      color: #2e3646;
      font-family: 'Helvetica', Arial, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 20px;

      span {
        color: #00a3ff;
      }
    }

    width: 40%;

    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .btn {
        display: inline-block;
        border: none;
        padding: 0;
        margin: 0;
        margin-right: 20px;
      }

      img {
        height: auto;
        width: 100%;
        max-width: 193px;
      }
    }
  }

  .banner-image {
    width: 60%;
    padding-top: 30px;

    figure {
      max-width: 585px;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  position: relative;
}

.container {
  max-width: 1760px;
  margin-left: 20px;
}

/*----------------------------- Responsive ------------------------------------------*/
@media only screen and (min-width: 1440px) {
  /* Your Styles... *
  .banner-image {
    figure {
      max-width: 450px;
    }
  }
}

@media only screen and (min-height: 900px) {
  /* Your Styles... */
  .footer {
    padding-top: 50px;
  }
  .banner-image {
    figure {
      max-width: 450px;
    }
  }
}

@media only screen and (min-width: 1440px) and (orientation: landscape) {
  /* Your Styles... */
  .footer {
    padding-top: 50px;
  }
  .banner-image {
    figure {
      max-width: 450px;
    }
  }
}

@media only screen and (min-width: 900px) and (orientation: portrait) {
  /* Your Styles... */
  .footer {
    padding-top: 50px;
  }
  .banner-image {
    figure {
      max-width: 450px;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1),
  only screen and (min--moz-device-pixel-ratio: 1),
  only screen and (-o-min-device-pixel-ratio: 1/1),
  only screen and (min-device-pixel-ratio: 1) {
  /* Your Styles here */
  .footer {
    padding-top: 50px;
  }
  .banner-image {
    figure {
      max-width: 450px;
    }
  }
}

@media screen and (max-width: 1700px) {
  .homepage-wrapper {
    .info {
      h1 {
        font-size: 50px;
      }

      h3 {
        font-size: 25px;
        max-width: 530px;
      }

      h4 {
        font-size: 25px;
      }

      .btn-wrap {
        .btn {
          margin-right: 15px;
        }

        img {
          max-width: 160px;
          height: 45px;
        }
      }
    }

    .banner-image {
      padding-top: 30px;

      figure {
        max-width: 450px !important;
      }
    }
  }

  .footer {
    padding-top: 0;
  }
}

@media screen and (max-width: 1440px) {
  .homepage-wrapper {
    .info {
      h1 {
        font-size: 45px;
      }

      h3 {
        font-size: 22px;
        max-width: 465px;
      }

      h4 {
        font-size: 22px;
      }

      .btn-wrap {
        .btn {
          margin-right: 10px;
        }

        img {
          max-width: 150px;
          height: 45px;
        }
      }
    }

    .banner-image {
      padding-top: 30px;

      figure {
        max-width: 450px;
      }
    }
  }

  .footer {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1299px) {
  .homepage-wrapper {
    height: calc(100vh - 200px);

    .banner-image {
      figure {
        max-width: 450px;
      }
    }
  }

  .footer {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .homepage-wrapper {
    .info {
      h1 {
        font-size: 40px;
      }

      h3 {
        font-size: 20px;
        max-width: 465px;
      }

      h4 {
        font-size: 20px;
      }

      .btn-wrap {
        img {
          max-width: 150px;
          height: 45px;
        }
      }
    }

    .banner-image {
      padding-top: 30px;

      figure {
        max-width: 350px;
      }
    }
  }

  .footer {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .page-container {
    position: relative;
    z-index: 1;
  }

  .page-container:before {
    position: absolute;
    content: '';
    opacity: 0.5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
  }

  .homepage-wrapper {
    .info {
      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 18px;
        max-width: 465px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 18px;
      }

      .btn-wrap {
        img {
          max-width: 125px;
          height: 35px;
        }
      }
    }

    .banner-image {
      padding-top: 30px;

      figure {
        max-width: 350px;
      }
    }
  }

  .footer {
    padding-top: 20px;
  }

  .page-container {
    background-size: cover;
  }
}

@media only screen and (max-width: 767px) {
  .homepage-wrapper {
    flex-direction: column;

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      text-align: center;

      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 18px;
        max-width: 465px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 18px;
      }

      .btn-wrap {
        img {
          max-width: 125px;
          height: 35px;
        }
      }
    }

    .banner-image {
      width: 100%;
      padding: 20px 0;

      figure {
        max-width: 300px;
      }
    }
  }

  .footer {
    padding-top: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .topheader-wrapper {
    margin-bottom: 20px;
  }
  .homepage-wrapper {
    flex-direction: column;

    .info {
      h1 {
        font-size: 28px;
      }

      h3 {
        max-width: 100%;
      }
    }

    .banner-image {
      figure {
        max-width: 250px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  #page-wrapper {
    min-height: calc(100vh - 20px);
    overflow-y: scroll;
  }
  .page-container {
    padding: 20px;
  }

  .homepage-wrapper {
    height: auto;
    flex-direction: column;

    .info {
      h1 {
        font-size: 24px;
      }
    }

    .banner-image {
      figure {
        max-width: 150px;
      }
    }
  }

  .footer p {
    font-size: 14px;
  }
}
