@import "_variable.scss";
*,
*::after,
*::before {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	background-color: $white-color;
	color: #2b3446;
}

::-webkit-scrollbar {
	width: 5px;
	border-radius: 5px;
	box-shadow: inset 0 0 4px -0.5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.5);
}

button {
	cursor: pointer;
	outline: none;
}

a {
	text-decoration: none;
}

ul {
	padding: 0;
	margin: 0;
}

ul {
	list-style: none;
}

button::-moz-focus-inner {
	border: 0;
}

a:hover,
a:active,
a:focus {
	outline: 0;
}

h1 {
	font-size: 3rem;

	&.size--small {
		font-size: 2.125rem;
	}
}

h2 {
	font-size: 2.4375rem;

	&.size--small {
		font-size: 2.0625rem;
	}
}

h3 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.75rem;
	}
}

h4 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.4375rem;
	}
}

h5 {
	font-size: 1.4375rem;

	&.size--small {
		font-size: 1.1875rem;
	}
}

h6 {
	font-size: 1.1875rem;

	&.size--small {
		font-size: 1rem;
	}
}

p {
	font-size: 0.875rem;
	line-height: 17px;
}
